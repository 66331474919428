import { Button } from '@ritmo/ui'

export default function ErrorPage({ error }: { error: Error }) {
  return (
    <div className="p-20">
      <h3>
        Ocurrió un error inesperado
      </h3>
      <small>
        Si el problema persiste, contacte a soporte.
      </small>
      <br />
      <br />
      <Button onClick={() => location.reload()}>
        Reiniciar
      </Button>
      <hr className="my-10" />
      <small>
        {error.message}
      </small>
    </div>
  )
}
