import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import LoginLayout from './components/Layouts/LoginLayout'
import LoginScreen from './screens/login'
import { Toaster } from 'react-hot-toast'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { isElectron } from './lib/isElectron'

const RECAPTCHA_KEY =
  process.env.REACT_APP_RECAPTCHA_KEY || (process.env.EXPO_PUBLIC_RECAPTCHA_KEY as string)
const Router = isElectron ? HashRouter : BrowserRouter

export default function LoginApp() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginLayout />}>
            <Route path="/login" element={<LoginScreen />} />
            <Route index element={<Navigate to="/login" replace />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>
    </GoogleReCaptchaProvider>
  )
}
