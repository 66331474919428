import { hasLocalDB } from '@ritmo/player-core'

export default async function checkBrowser() {
  // check if all local db's are working
  if (!await hasLocalDB()) {
    throw new Error('Local DB not working')
  }

  // check if estimate works
  const { quota, usage } = await navigator.storage.estimate()
  if (typeof quota !== 'number' || typeof usage !== 'number') {
    throw new Error('Storage estimate not working')
  }
}
