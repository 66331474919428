import { Plugs } from 'phosphor-react'
import { useConnection } from '../../contexts/connection'
import { useEffect, useRef } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import headerLogo from '../../components/Header/logo.svg'

export default function ConnectionNeededScreen() {

  const toastIdRef = useRef<string | null>(null)
  const connection = useConnection()

  useEffect(() => {
    toastIdRef.current = toast.loading('Esperando conexión')
  }, [])

  useEffect(() => {
    if (connection.online) {
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current)
      }
      toast.success('Conectado')
      setTimeout(() => location.reload(), 1000)
    }
  }, [connection.online])

  return (
    <div className="h-s-screen">
      <img
        src={headerLogo}
        className="filter absolute left-6 top-6"
      />
      <div className="h-full flex items-center justify-center flex-col gap-4 bg-gray-100">
        <Plugs size={40} className="opacity-50" />
        <h4>Conexión a internet requerida</h4>
      </div>
      <Toaster />
    </div>
  )
}
