import { isElectron } from '../../lib/isElectron'
import logo from './logo.svg'
import { useEffect, useState } from 'react'

const splash_timeout = isElectron ? 0 : 1000

export default function SplashScreen() {

  const [render, setRender] = useState(false)

  useEffect(() => {
    const id = setTimeout(() => setRender(true), splash_timeout)
    return () => clearTimeout(id)
  }, [])

  if (!render) {
    return <></>
  }

  return (
    <div className="h-s-screen flex items-center justify-center flex-col gap-4 bg-gray-100">
      <img src={logo} className="w-6/12 max-w-xs" />
    </div>
  )
}
