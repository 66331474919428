import { FileX, GoogleChromeLogo } from 'phosphor-react'

export default function UnsupportedBrowser() {
  return (
    <div className="p-20">
      <h1>
        <FileX />
      </h1>
      <br />
      <h3>
        Navegador no soportado
      </h3>
      <small className="mt-4">
        Parece que estás intentando usar RITMO en un navegador no soportado. Asegurate de estar usando <GoogleChromeLogo size={16} className="inline-flex" /> Google Chrome en su última versión.
      </small>
      <br />
      <hr className="my-10" />
      <small>
        Si el problema persiste, contactá a soporte.
      </small>
    </div>
  )
}
