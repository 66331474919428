export default function PrivacyPolicy() {
  return (
    <div className="max-w-xl mx-auto px-5">
      <br />
      <br />
      <h1>Política de Privacidad RITMO</h1>
      <br />
      <br />
      <h3>Introducción</h3>
      Bienvenido a la Política de Privacidad de la aplicación RITMO. En RITMO, valoramos y respetamos tu privacidad. Esta política describe cómo recopilamos, utilizamos y protegemos la información que nos proporcionas a través de nuestra aplicación. Al utilizar nuestra aplicación, aceptas las prácticas descritas en esta política.
      <br />
      <br />
      <h3>Información que Recopilamos</h3>
      Información de la Tienda
      Cuando utilizas la aplicación RITMO, recopilamos el nombre del cliente y la nomenclatura de la tienda a la que perteneces. Este se utiliza exclusivamente para personalizar tu experiencia en la aplicación. Además recopilamos información básica del dispositivo desde donde se instala nuestra app, al mismo tiempo que se recopila información sobre los niveles de conectividad de red de dicho dispositivo.
      <br />
      <br />
      <h3>Uso de la Información</h3>
      Utilizamos la información de la tienda para los siguientes propósitos:
      <br />
      <b>- Personalización de la Experiencia:</b> Utilizamos el nombre de la tienda para personalizar tu experiencia en RITMO, como recomendarte música que sea relevante para tu tienda.
      <br />
      <b>- Comunicación:</b> Podemos utilizar esta información para enviar notificaciones relacionadas con tu tienda y actualizaciones de la aplicación, reportes de uso o cualquier evento especial que afecte la experiencia de la tienda.
      <br />
      <br />
      <h3>Compartir Información</h3>
      No compartimos la información de la tienda con terceros, excepto en las siguientes circunstancias:
      Cumplimiento Legal: Podemos divulgar la información de la tienda si estamos legalmente obligados a hacerlo.
      <br />
      <br />
      <h3>Seguridad de la Información</h3>
      Tomamos medidas para proteger la información de la tienda y garantizar la seguridad de los datos:
      Cifrado de Datos: los datos de la tienda proporcionados por los usuarios se cifran durante la transmisión y almacenamiento con cifrado SSL/TLS para proteger la información durante la comunicación.
      Acceso Restringido: el acceso a la información de la tienda está restringido solo a empleados autorizados que necesitan acceso para llevar a cabo sus funciones.
      Monitoreo de Seguridad: la aplicación RITMO se somete a monitoreo de seguridad para detectar y mitigar posibles amenazas y vulnerabilidades. Esto incluye pruebas de penetración y análisis de seguridad continuos.
      Respaldo de Datos: se realizan copias de seguridad periódicas de la información de la tienda para garantizar la disponibilidad de los datos en caso de fallas técnicas o pérdida de datos.
      <br />
      <br />
      <h3>Cambios en esta Política</h3>
      Podemos actualizar esta política ocasionalmente para reflejar cambios en nuestras prácticas de privacidad. Te notificaremos sobre cambios significativos a través de la aplicación.
      <br />
      <br />
      <h3>Preguntas y Contacto</h3>
      Si tienes alguna pregunta sobre esta política de privacidad contáctanos a través de nuestro correo electrónico: soporte@ritmostudio.com.
      Gracias por utilizar RITMO!
    </div>
  )
}