import { useRecoilValue } from 'recoil'
import { Button, ControlButtons, Modal, PlayBar, ProgrammingToast, ProgressInfo } from '@ritmo/ui'
import {
  currentTrackState,
  playingState,
  timerState,
  willPlayOneShotState,
} from '../../atoms/playback'
import { programmingState } from '../../atoms/programming'
import { useCallback, useState } from 'react'
import { endSong, isAxiosError, playOrPause, sendDislike } from '@ritmo/player-core'
import usePermissions from '../../lib/usePermissions'
import { Song } from '@ritmo/types'
import toast from 'react-hot-toast'

export function PlayerProgrammingToast() {
  const programmingInfo = useRecoilValue(programmingState)
  if (!programmingInfo.active) return null
  return <ProgrammingToast />
}

function MobileProgress() {
  const { step } = useRecoilValue(timerState)
  return (
    <div className="flex md:hidden bg-slate-300 h-1">
      <div style={{ width: `${step}%` }} className="bg-accent h-full rounded-r-full" />
    </div>
  )
}

function PlayerProgressInfo() {
  const { timer, step } = useRecoilValue(timerState)
  return <ProgressInfo timer={timer} step={step} className="mt-2 hidden md:block" />
}

export function PlayerPlayBar() {
  const { track, streaming } = useRecoilValue(currentTrackState)
  const playing = useRecoilValue(playingState)
  const permissions = usePermissions()
  const canAccessQueue = permissions.includes('get_current_queue')
  const canDislike = permissions.includes('dislike')
  const willPlayOneShot = useRecoilValue(willPlayOneShotState)
  const [trackToDislike, setTrackToDislike] = useState<string | null>(null)

  const handleNextSong = useCallback(() => {
    endSong()
  }, [])

  const handlePlayOrPause = useCallback(() => {
    playOrPause()
  }, [])

  const handleDislike = useCallback(() => {
    if (!track?._id) return
    setTrackToDislike(track?._id)
  }, [track?._id])

  const dislikeTrack = useCallback(async () => {
    if (!trackToDislike) return
    try {
      await sendDislike(trackToDislike)
      toast.success('¡Gracias por tu feedback!')
    } catch (err) {
      if (isAxiosError(err) && err.response?.status === 400) {
        toast('Ya has dado dislike a esta canción')
      } else {
        toast.error('Hubo un error al enviar tu feedback, por favor intenta de nuevo')
      }
    } finally {
      setTrackToDislike(null)
    }
  }, [trackToDislike])

  return (
    <>
      <PlayBar
        toast={
          <>
            <PlayerProgrammingToast />
            <MobileProgress />
          </>
        }
        track={track}
        streaming={streaming}
        progressInfo={<PlayerProgressInfo />}
        controlButtons={
          <ControlButtons
            canAccessQueue={canAccessQueue}
            onDislike={canDislike ? handleDislike : undefined}
            playing={playing}
            onNextSong={handleNextSong}
            onPlayOrPause={handlePlayOrPause}
            disabled={willPlayOneShot}
          />
        }
      />
      <Modal open={!!trackToDislike}>
        <h3>Tu feedback es importante para nosotros</h3>
        <p className="mt-2 mb-10">¿Estás seguro que quieres darle dislike a la canción actual?</p>
        <div className="flex justify-between items-center mt-4">
          <Button onClick={() => setTrackToDislike(null)} variant="secondary">
            Cancelar
          </Button>
          <Button onClick={dislikeTrack} variant="danger">
            Si, dar dislike
          </Button>
        </div>
      </Modal>
    </>
  )
}
