export async function hardReload() {
  try {
    const [registration] = await navigator.serviceWorker.getRegistrations()
    await registration.update()
    registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
  } catch (error) {
    console.warn(error)
  } finally {
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}
