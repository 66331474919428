import { atom } from 'recoil'
import { Song } from '@ritmo/types'

export const defaultTimerState = {
  timer: '00:00',
  step: 0,
}

export interface TimerState {
  timer: string
  step: number
}

export const playingState = atom<boolean>({
  key: 'playingState',
  default: false,
})

export const timerState = atom<TimerState>({
  key: 'timerState',
  default: defaultTimerState,
})

export interface CurrentTrackState {
  track: Song | null
  streaming: boolean
}

export const currentTrackState = atom<CurrentTrackState>({
  key: 'currentTrackState',
  default: {
    track: null,
    streaming: false,
  },
})

export const willPlayOneShotState = atom<boolean>({
  key: 'willPlayOneShot',
  default: false,
})
