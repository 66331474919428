import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import Layout from './components/Layouts/Layout'
import Info from './screens/info'
import PlaylistsScreen from './screens/playlists'
import { Toaster } from 'react-hot-toast'
import QueueScreen from './screens/queue/QueueScreen'
import AlreadyActiveSession from './screens/already-active-session'
import { RecoilRoot } from 'recoil'
import StayAlive from './components/StayAlive'
import { ErrorBoundary } from '@sentry/react'
import ErrorPage from './ErrorPage'
import AutoLogin from './screens/auto-login'
import PrivacyPolicy from './screens/privacy-policy'
import { isElectron } from './lib/isElectron'
import usePermissions from './lib/usePermissions'

const Router = isElectron ? HashRouter : BrowserRouter

function App() {
  const permissions = usePermissions()
  const canAccessQueue = permissions.includes('get_current_queue')

  return (
    <ErrorBoundary fallback={ErrorPage}>
      <RecoilRoot>
        <Router>
          <Routes>
            <Route path="/auto-login" element={<AutoLogin />} />
            <Route path="/" element={<Layout />}>
              <Route index element={<PlaylistsScreen />} />
              <Route path="/info" element={<Info />} />
              <Route
                path="/queue"
                element={canAccessQueue ? <QueueScreen /> : <Navigate to="/" replace />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="/already-active-session" element={<AlreadyActiveSession />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
        <Toaster />
        <StayAlive />
      </RecoilRoot>
    </ErrorBoundary>
  )
}

export default App
