import { Howl } from 'howler'
import { setCreateSoundInstance, SoundInstance } from '@ritmo/player-core'

setCreateSoundInstance(async creationProps => {

  const sound = new Howl({
    src: creationProps.filePath,
    html5: true,
    format: ['mp3'],
    preload: 'metadata',
  })

  function waitForLoad() {
    return new Promise<void>(resolve => {
      sound.once('load', resolve)
    })
  }

  function unload() {
    sound.unload()
    URL.revokeObjectURL(creationProps.filePath)
  }

  sound.on('play', () => {
    creationProps.onPlay()
  })
  sound.on('pause', () => {
    creationProps.onPause()
  })
  sound.on('playerror', () => {
    unload()
    creationProps.deleteInstance()
    creationProps.resolve()
  })
  sound.on('loaderror', () => {
    unload()
    creationProps.resolve()
  })
  sound.on('end', () => {
    unload()
    creationProps.onEnd()
  })
  sound.on('stop', () => {
    unload()
    creationProps.onEnd()
  })

  await waitForLoad()

  const soundInstance: SoundInstance = {
    play: () => sound.play(),
    pause: async () => {
      sound.pause()
    },
    end: async () => {
      sound.stop()
    },
    setVolume: async (volume: number) => {
      sound.volume(volume)
    },
    isPlaying: async () => sound.playing(),
    getVolume: async () => sound.volume(),
    getDuration: async () => sound.duration(),
    getSeek: async () => sound.seek(),
    // development and testing only
    _seekTo12SecondsBeforeEnd: async () => {
      sound.seek(sound.duration() - 12)
    }
  }

  return soundInstance
})
