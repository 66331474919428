import { PianoKeys } from 'phosphor-react'
import useSWR from 'swr'
import { getTodaysSchedule } from '@ritmo/player-core'

const emptyToast = (
  <div className="flex items-center py-5 bg-slate-200/50 rounded-md px-5 max-w-md space-x-5">
    <PianoKeys size={40} opacity={0.8} className="text-accent" />
    <div className="text-gray-800">
      <h4>No hay horarios para hoy</h4>
      <p>La pista es toda tuya! 😎</p>
    </div>
  </div>
)

export default function ScheduleInfo() {

  const { data: todaysSchedule } = useSWR(
    'todays-schedule',
    getTodaysSchedule,
    { refreshInterval: 2000 }
  )

  if (!todaysSchedule) return null

  const dot = (
    <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-accent/70 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-3 w-3 bg-accent"></span>
    </span>
  )

  return (
    <div className="space-y-3" >
      {!todaysSchedule.length && emptyToast}
      {todaysSchedule.map(({ set, isPlaying, legibleStart, legibleEnd }, i) => (
        <div key={i} className="relative w-full max-w-md">
          <div className="rounded-md p-4 bg-accent/5 overflow-y-auto whitespace-nowrap first-letter:capitalize" >
            {legibleStart}
            <span className="opacity-50 mx-2">→</span>
            {legibleEnd}
            <span className="opacity-20 mx-4">⎯⎯</span>
            {set.title}
          </div>
          {isPlaying ? dot : null}
        </div>
      ))}
    </div>
  )
}