import { createContext, ReactNode, useContext, useMemo } from 'react'
import { apiReachable } from '@ritmo/player-core'
import useIntervalGet from '../lib/useIntervalGet'

interface ConnectionContext {
  online: boolean
}

const connectionContext = createContext<ConnectionContext>({
  online: true,
})

const getApiReachable = () => apiReachable

export default function ConnectionProvider({ children }: { children: ReactNode }) {

  const online = useIntervalGet(getApiReachable)

  const value = useMemo(() => ({ online }), [online])

  return (
    <connectionContext.Provider value={value}>
      {children}
    </connectionContext.Provider>
  )
}

export function useConnection() {
  return useContext(connectionContext)
}