import { setStorageEstimate } from '@ritmo/player-core'

export async function getStorageEstimate(): Promise<{ usageInMB: number, quotaInMB: number }> {
  const { quota, usage } = await navigator.storage.estimate()
  if (typeof quota !== 'number' || typeof usage !== 'number') {
    throw new Error('NO_STORAGE_ESTIMATE')
  }
  const usageInMB = usage / 1048576
  const quotaInMB = quota / 1048576
  return { usageInMB, quotaInMB }
}

setStorageEstimate(getStorageEstimate)
