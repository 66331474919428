import { toast } from 'react-hot-toast'
import { LoginData, apiResponses, login as coreLogin, isAxiosError } from '@ritmo/player-core'

export const login = async (loginData: LoginData) => {
  try {
    await coreLogin(loginData)
    toast.success('Bienvenido!')
    setTimeout(() => {
      location.reload()
    }, 1000)
  } catch (error) {
    if (isAxiosError(error)) {
      const jsonError = error.toJSON() as { status: number }
      if (jsonError.status === 429) {
        return toast.error('Demasiados intentos, intente nuevamente en 1 hora.')
      }
      if (error.response) {
        const message = apiResponses[error.response.data as keyof typeof apiResponses]
        toast.error(message || 'Usuario o contraseña incorrectos')
      }
    } else {
      console.log(error)
      toast.error('Error al procesar la petición')
    }
  }
}