import { ArrowsClockwise, Warning } from 'phosphor-react'
import { Progress, Modal } from '@ritmo/ui'
import { getDownloadPercentage, getOverallStatus } from '@ritmo/player-core'
import useSWR from 'swr'

export default function WaitToSyncModal() {
  const { data: overallStatus } = useSWR('overall-status', getOverallStatus, {
    refreshInterval: 1000,
    refreshWhenOffline: true,
  })

  const { data: overallProgress } = useSWR('overall-progress', getDownloadPercentage, {
    refreshInterval: 1000,
  })

  return (
    <Modal open className="w-10/12 max-w-md">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {!overallProgress ? 'Configuración inicial' : 'Descargando música'}
        </h3>
        {!overallProgress ? <ArrowsClockwise className="opacity-80 animate-spin" /> : null}
      </div>
      {overallProgress ? (
        <div className="mt-4">
          <Progress animated max={10} value={overallProgress} />
          {overallStatus === 'no_internet' && (
            <p className="text-sm text-gray-500 mt-3 flex items-center gap-x-2">
              <ArrowsClockwise className="opacity-80 animate-spin" />
              Descarga pausada, esperando conexión a internet
            </p>
          )}
          {overallStatus === 'no_space' && (
            <p className="text-sm text-gray-500 mt-3 flex items-center gap-x-2">
              <Warning className="opacity-80 text-orange-600" />
              Espacio no suficiente para continuar descargando música
            </p>
          )}
        </div>
      ) : (
        <p className="text-sm text-gray-500 mt-2">Esto puede durar unos segundos...</p>
      )}
    </Modal>
  )
}
