import { useRecoilState, useRecoilValue } from 'recoil'
import useSWR from 'swr'
import { playingState, willPlayOneShotState } from '../../atoms/playback'
import { currentPlaylistIdState } from '../../atoms/playlists'
import { getAllDocsByKey, getDownloadDetails, playOrPause } from '@ritmo/player-core'
import { Playlist, Partitions } from '@ritmo/types'
import { programmingState } from '../../atoms/programming'
import { DownloadIndicator, PlaylistItem } from '@ritmo/ui'
import { playPlaylist } from '@ritmo/player-core'
import toast from 'react-hot-toast'

export default function PlaylistsScreen() {
  const { data: playlists } = useSWR<Playlist[]>(Partitions.PLAY, getAllDocsByKey, {
    refreshInterval: 2000,
  })
  const { data: downloadDetails } = useSWR('download-details', getDownloadDetails, {
    refreshInterval: 2000,
    refreshWhenOffline: true,
  })
  const [currentPlaylistId, setCurrentPlaylistId] = useRecoilState(currentPlaylistIdState)
  const playing = useRecoilValue(playingState)
  const programmingInfo = useRecoilValue(programmingState)
  const willPlayOneShot = useRecoilValue(willPlayOneShotState)

  const handlePlaylistClick = async (playlistId: string) => {
    if (playlistId === currentPlaylistId) {
      return playOrPause()
    }
    // new playlist, pick new song
    setCurrentPlaylistId(playlistId)
    const response = await playPlaylist(playlistId)
    if (response === 'after_this_song') {
      toast.success('Se reproducirá despues de la canción actual')
    }
  }

  if (!playlists) return null

  return (
    <div className="p-5">
      <h3>Playlists</h3>
      <div className="h-5" />
      <div
        className={`
          grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 md:gap-4
          ${programmingInfo.active || willPlayOneShot ? 'opacity-50' : ''} 
        `}
      >
        {playlists.map((playlist) => (
          <PlaylistItem
            key={playlist._id}
            {...playlist}
            isCurrent={playlist._id === currentPlaylistId}
            onClick={() => handlePlaylistClick(playlist._id)}
            playing={playlist._id === currentPlaylistId && playing}
            disabled={programmingInfo.active || willPlayOneShot}
            image={playlist.image}
            extra={
              downloadDetails ? (
                <div className="flex items-center space-x-1">
                  {downloadDetails[playlist._id]?.status !== 'complete' && (
                    <span className="opacity-60 text-xs">
                      {Math.round(downloadDetails[playlist._id]?.percentage)}%
                    </span>
                  )}
                  <DownloadIndicator status={downloadDetails[playlist._id]?.status} />
                </div>
              ) : null
            }
          />
        ))}
      </div>
    </div>
  )
}
