import { Outlet } from 'react-router-dom'
import logo from './logo-and-slogan.svg'
import tonicaLogo from './tonica_logo_white.svg'
import headerLogo from '../Header/logo.svg'
import headerTonicaLogo from '../Header/tonica_logo.svg'
import { isTonica } from '../../isTonica'

export default function LoginLayout() {
  return (
    <div className="h-s-screen flex">
      <div className="w-full md:w-6/12 lg:w-5/12 relative">
        <img
          src={isTonica ? headerTonicaLogo : headerLogo}
          className="filter md:hidden absolute left-6 top-6 h-8"
        />
        <Outlet />
      </div>
      <div className={`
      bg-accent items-center justify-center
        hidden md:flex flex-1
        
      `}>
        <img className="max-w-md" src={isTonica ? tonicaLogo : logo} />
      </div>
    </div>
  )
}