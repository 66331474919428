import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { Link } from 'react-router-dom'
import { ArrowLeft, MagnifyingGlass } from 'phosphor-react'
import AutoResizer from 'react-virtualized-auto-sizer'

import SongItem from './SongItem'
import { endSong, queue, queueIndex, setQueueIndex } from '@ritmo/player-core'
import useIntervalGet from '../../lib/useIntervalGet'
import { useQueueSearch } from './useQueueSearch'

export default function QueueScreen() {
  const listRef = useRef<FixedSizeList>()
  const headerRef = useRef<HTMLDivElement>(null)
  const queueIndexState = useIntervalGet(() => queueIndex, 1000)
  const [search, setSearch] = useState('')

  const searchResult = useQueueSearch(search)

  const filteredQueue = useMemo(() => {
    if (!searchResult) return queue
    return queue.filter(trackId => searchResult.includes(trackId))
  }, [searchResult])

  const listRefHandler = useCallback((listElement: FixedSizeList<any>) => {
    listRef.current = listElement
    listElement?.scrollToItem(queueIndexState, 'center')
  }, [queueIndexState])

  useEffect(() => {
    listRef.current?.scrollToItem(queueIndexState)
  }, [queueIndexState])

  const playSongById = (id: string) => {
    const realIndex = queue.indexOf(id)
    setQueueIndex(realIndex - 1)
    endSong()
  }

  const currentTrackId = queue[queueIndexState]

  const searchInput = (
    <input
      className="input ml-auto"
      placeholder="Buscar"
      value={search}
      onChange={e => setSearch(e.target.value)}
    />
  )

  const list = filteredQueue.length ? (
    <AutoResizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height - 80}
          width={width}
          itemCount={filteredQueue.length}
          itemSize={65}
          ref={listRefHandler}
        >
          {({ index, style }) => (
            <div style={style}>
              <SongItem
                trackId={filteredQueue[index]}
                playing={currentTrackId === filteredQueue[index]}
                onClick={() => playSongById(filteredQueue[index])}
              />
            </div>
          )}
        </FixedSizeList>
      )}
    </AutoResizer>
  ) : (
    <div className="mt-10 text-center opacity-80 rounded-lg bg-slate-200 py-5">
      <MagnifyingGlass size={20} className="mx-auto mb-2" />
      <h5>Búsqueda sin resultados</h5>
    </div>
  )

  return (
    <div className="p-5 h-full">
      <div className="flex items-center justify-between mb-3 md:mb-5">
        <h3
          ref={headerRef}
          className="flex items-center"
        >
          <Link to="/" className="mr-4">
            <ArrowLeft />
          </Link>
          Lista actual
        </h3>
        {searchInput}
      </div>
      {list}
    </div>
  )
}
