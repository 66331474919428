import { useEffect, useState } from 'react'

export default function useIntervalGet<T>(getter: () => T, interval = 2000) {
  const [value, setValue] = useState(getter())

  useEffect(() => {
    const i = setInterval(() => setValue(getter()), interval)
    return () => clearInterval(i)
  }, [interval, getter])

  return value
}