import { useCallback } from 'react'
import sound from './alive-sound.wav'

export default function StayAlive() {

  const audioRef = useCallback((audio: HTMLAudioElement) => {
    if (!audio) return
    audio.volume = 0.0001

    const play = async () => {
      try {
        await audio.play()
      } catch (err) {
        console.error(err)
      }
    }
    play()
    setInterval(play, 10 * 1000)
  }, [])

  return (
    <audio ref={audioRef}>
      <source src={sound} type="audio/wav" />
    </audio>
  )
}