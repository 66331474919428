import { Button } from '@ritmo/ui'
import { MusicNote } from 'phosphor-react'

export default function AlreadyActiveSession() {
  const queryParams = new URLSearchParams(window.location.search)
  const isInitiatingDesktopApp = queryParams.get('prevUrl') === '/auto-login'

  return (
    <div className="h-s-screen flex items-center justify-center flex-col gap-4 bg-gray-100">
      <MusicNote size={40} className="opacity-50" />
      {isInitiatingDesktopApp ? (
        <h4>Listo! Ya puedes cerrar esta ventana.</h4>
      ) : (
        <>
          <h4>Tienes una sesion iniciada en otra pestaña</h4>
          <Button
            className="mt-4"
            onClick={() => window.location.href = '/'}
          >
            Usar aquí
          </Button>
        </>
      )}
    </div>
  )
}
