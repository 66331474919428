import { Branch } from '@ritmo/types'
import { Dropdown, MenuItem } from '@ritmo/ui'
import { ArrowsClockwise, CaretDown, Info } from 'phosphor-react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { getProfile } from '@ritmo/player-core'
import { useConnection } from '../../contexts/connection'
import { hardReload } from '../../lib/hardReload'

export default function HeaderDropdown() {
  const { data: profile } = useSWR<Branch>('profile', getProfile, { refreshInterval: 2000 })
  const connection = useConnection()

  const trigger = (
    <button className="flex items-center px-2 py-1 rounded-md hover:bg-slate-50 pl-3 no-highlight">
      <div className="text-left">
        <div className="text-sm capitalize">{profile?.clientName}</div>
        <div className="text-xs opacity-50 capitalize">{profile?.title}</div>
      </div>
      <CaretDown weight="bold" className="ml-4" />
    </button>
  )

  return (
    <Dropdown renderTrigger={trigger}>
      <Link to="/info">
        <MenuItem>
          <Info size={24} className="mr-2" /> Info
        </MenuItem>
      </Link>
      {/* <Link to="/help">
        <MenuItem>
          <Chat size={24} className="mr-2" /> Ayuda
        </MenuItem>
      </Link> */}
      <MenuItem disabled={!connection.online} onClick={hardReload}>
        <ArrowsClockwise size={24} className="mr-2" /> Limpiar caché
      </MenuItem>
    </Dropdown>
  )
}
