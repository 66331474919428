import useSWR from 'swr'
import { getAvailableSpaceInMB } from '@ritmo/player-core'

export default function useSpaceInfo() {
  const { data } = useSWR(
    'storage-info',
    getAvailableSpaceInMB,
    {
      refreshInterval: 10 * 1000,
      fallbackData: 9999
    }
  )
  return data
}
