import { CaretUp } from 'phosphor-react'
import { ReactNode, useState } from 'react'
import { getCurrentSongId, queue, queueIndex, songsFromLastAd, currentShift, endSong, _seekTo12SecondsBeforeEnd } from '@ritmo/player-core'
import useIntervalGet from '../../lib/useIntervalGet'
import { Button } from '@ritmo/ui'
import DevOnly from './DevOnly'

const Desc = (
  { label, children }: { label: string, children: ReactNode }
) => (
  <div>
    <small>{label}</small><br />
    {children}
  </div>
)

const getSongsFromLastAd = () => songsFromLastAd
const getQueue = () => queue
const getQueueIndex = () => queueIndex
const getCurrentShift = () => currentShift

function logSoundInfo() {
  console.log({
    // playing: sound?.playing(),
    // state: sound?.state(),
    // duration: sound?.duration(),
    // position: sound?.seek(),
    // volume: sound?.volume(),
    // rate: sound?.rate(),
  })
}

function PlayBarDevOptions() {

  const songsFromLastAdState = useIntervalGet(getSongsFromLastAd)
  const currentSongId = useIntervalGet(getCurrentSongId)
  const queue = useIntervalGet(getQueue)
  const queueIndex = useIntervalGet(getQueueIndex)
  const currentShift = useIntervalGet(getCurrentShift)
  const [expanded, setExpanded] = useState(true)

  const adsDevInfo = (
    <div>
      <Desc label="Current shift">
        {currentShift?.title || '-'}
      </Desc>
      <Desc label="Songs Between ad">
        {currentShift?.songsBetweenAd || '-'}
      </Desc>
      <Desc label="Songs from last ad">
        {songsFromLastAdState}
      </Desc>
    </div>
  )

  const queueDevInfo = (
    <div>
      <Desc label="Current song id">
        {currentSongId?.slice(-7)}
      </Desc>
      <Desc label="Queue length">
        {queue.length}
      </Desc>
      <Desc label="Queue index">
        {queueIndex}
      </Desc>
    </div>
  )

  return (
    <div className="absolute z-50 right-5 md:right-8 bottom-24 border border-slate-300 rounded-lg backdrop-filter backdrop-blur-md bg-white bg-opacity-40">
      <div
        className={`
          flex w-full justify-between items-center cursor-pointer space-x-3 px-4
          ${expanded ? 'py-3' : 'py-2'}
        `}
        onClick={() => setExpanded(!expanded)}
      >
        <h4>Dev tools</h4>
        <CaretUp
          size={20}
          className={`transform transition ${expanded ? 'rotate-180' : ''}`}
        />
      </div>
      {expanded ? (
        <div className="flex p-4 border-t gap-x-10">
          {adsDevInfo}
          {queueDevInfo}
          <div className="space-y-2">
            <div>
              <Button size="small" onClick={endSong}>
                End song
              </Button>
            </div>
            <div>
              <Button size="small" variant="secondary" onClick={logSoundInfo}>
                Log sound
              </Button>
            </div>
            <div>
              <Button size="small" onClick={_seekTo12SecondsBeforeEnd}>
                {'12" before end'}
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default function PlayBarDevOptionsWithDev() {
  return (
    <DevOnly>
      <PlayBarDevOptions />
    </DevOnly>
  )
}
