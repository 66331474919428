import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import AlreadyActiveSession from './screens/already-active-session'
import { ErrorBoundary } from '@sentry/react'
import ErrorPage from './ErrorPage'
import AutoLogin from './screens/auto-login'
import PrivacyPolicy from './screens/privacy-policy'
import { isElectron } from './lib/isElectron'

const Router = isElectron ? HashRouter : BrowserRouter

export default function OutsideMainFlowApp() {

  return (
    <ErrorBoundary fallback={ErrorPage} >
      <Router>
        <Routes>
          <Route path="/auto-login" element={<AutoLogin />} />
          <Route path="/already-active-session" element={<AlreadyActiveSession />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      <Toaster />
    </ErrorBoundary>
  )
}
