import { DownloadIndicator, Progress } from '@ritmo/ui'
import { DownloadDetailsState } from '@ritmo/player-core'
import DevOnly from '../Dev/DevOnly'

interface DownloadDetailProps extends Omit<DownloadDetailsState, 'playlist'> {
  playlistName?: string
}

export default function DownloadDetail({
  playlistName,
  blobsQ,
  playlistSongsQ,
  percentage,
  status,
}: DownloadDetailProps) {
  return (
    <div>
      <div className="text-sm opacity-60 flex items-center justify-between mb-2">
        <h5>{playlistName}</h5>
        <div className="flex items-center gap-x-2">
          <DevOnly>
            <div className="mr-4 text-xs border px-1 py-0.5 rounded">
              {blobsQ} / {playlistSongsQ} (dev only)
            </div>
          </DevOnly>
          {Math.round(percentage)}%
          <DownloadIndicator status={status} />
        </div>
      </div>
      <Progress value={percentage} animated />
    </div>
  )
}