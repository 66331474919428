import { logout, socket } from '@ritmo/player-core'
import { hardReload } from './hardReload'

export const initSocket = () => {
  socket.on('force-refresh', hardReload)
  socket.on('force-logout', async () => {
    await logout()
    location.reload()
  })
  socket.on('kick-out', () => {
    window.location.href = `${window.origin}/already-active-session?prevUrl=${window.location.pathname}`
  })
}
