import { getLocalToken } from '@ritmo/player-core'
import { useEffect, useState } from 'react'

const handleLogin = async () => {
  const resp = await getLocalToken()
  if (!resp?.token) throw new Error('No token')
  window.location.href = `ritmo://open?token=${resp.token}&refresh_token=${resp.refresh_token}`
}

export default function AutoLogin() {
  const [noToken, setNoToken] = useState(false)

  useEffect(() => {
    handleLogin()
      .catch(() => setNoToken(true))
  }, [])

  if (noToken) {
    return (
      <div className="h-screen flex items-center justify-center flex-col gap-4 bg-gray-100">
        <h4>No tienes una sesión iniciada en este navegador</h4>
      </div>
    )
  }

  return null
}