import { Disc, WifiX } from 'phosphor-react'
import { useMemo } from 'react'
import { useConnection } from '../../contexts/connection'
import { LOW_SPACE_ALERT, LOW_SPACE_STOP_DOWNLOAD, getProfile } from '@ritmo/player-core'
import useSpaceInfo from '../../lib/useSpaceInfo'
import HeaderDropdown from './Dropdown'
import logo from './logo.svg'
import tonicaLogo from './tonica_logo.svg'
import useSWR from 'swr'
import { Branch } from '@ritmo/types'
import { isTonica } from '../../isTonica'

interface HeaderProps {
  syncIndicator?: JSX.Element
}

export default function Header({ syncIndicator }: HeaderProps) {

  const { online } = useConnection()
  const { data: profile } = useSWR<Branch>('profile', getProfile, { refreshInterval: 2000 })

  const availableSpace = useSpaceInfo()
  const offlineIndicator = !online && (
    <span className="bg-accent/10 py-1 px-2 rounded-md text-accent text-sm">
      <WifiX />
    </span>
  )

  const lowSpaceInfo = useMemo(() => {
    if (typeof availableSpace !== 'number') return null
    return availableSpace < LOW_SPACE_ALERT && (
      <div className="px-4 sm:px-5 md:px-10 py-2 md:py-3 bg-amber-600 text-white flex items-center gap-4">
        <Disc size={32} />
        <div>
          <h4>Poco espacio en el dispositivo</h4>
          <p className="text-sm opacity-80">
            Libera espacio para que la música pueda descargarse.
          </p>
        </div>
      </div>
    )
  }, [availableSpace])

  const redAlertSpace = useMemo(() => {
    if (typeof availableSpace !== 'number') return null
    return availableSpace < LOW_SPACE_STOP_DOWNLOAD && (
      <div className="px-4 sm:px-5 md:px-10 py-2 md:py-3 bg-red-600 text-white flex items-center gap-4">
        <Disc size={32} />
        <div>
          <h4>Sin espacio en el dispositivo</h4>
          <p className="text-sm opacity-80">
            Ritmo no está funcionando correctamente. Libera espacio para que la música pueda descargarse.
          </p>
        </div>
      </div>
    )
  }, [availableSpace])

  const reachedMaxDownloads = useMemo(() => {
    if (!profile?.maxDownloadsAlert) return null
    return (
      <div className="px-4 sm:px-5 md:px-10 py-2 md:py-3 bg-red-600 text-white flex items-center gap-4">
        <Disc size={32} />
        <div>
          <h4>Has alcanzado el límite de descargas</h4>
          <p className="text-sm opacity-80">
            No podras descargar más música.
          </p>
        </div>
      </div>
    )
  }, [profile?.maxDownloadsAlert])

  return (
    <div>
      <div
        className={`
          px-4 sm:px-5 md:px-10 py-2 md:py-3 bg-white
          flex justify-between items-center relative
        `}
      >
        <span className="flex items-center space-x-4">
          <img
            className="h-8"
            src={isTonica ? tonicaLogo : logo}
          />
          {syncIndicator}
          {offlineIndicator}
        </span>
        <HeaderDropdown />
      </div>
      {redAlertSpace || lowSpaceInfo || reachedMaxDownloads}
    </div>
  )
}