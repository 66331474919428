import useSWR from 'swr'
import DownloadDetail from '../../components/DownloadDetail/DownloadDetail'
import {
  getAllDocsByKey,
  getDownloadDetails,
} from '@ritmo/player-core'
import { Playlist, Partitions } from '@ritmo/types'

export default function DownloadInfo() {

  const { data: playlists } = useSWR<Playlist[]>(
    Partitions.PLAY,
    getAllDocsByKey,
    { refreshInterval: 2000, fallbackData: [] }
  )
  const { data: downloadPerPlaylist } = useSWR(
    'download-details',
    getDownloadDetails,
    { refreshInterval: 2000, fallbackData: {} }
  )

  return (
    <div className="max-w-xs divide-y">
      {playlists?.map(playlist => (
        <div
          key={playlist._id}
          className="py-4"
        >
          {downloadPerPlaylist?.[playlist._id] ? (
            <DownloadDetail
              playlistName={playlist.title}
              {...downloadPerPlaylist?.[playlist._id]}
            />
          ) : null}
        </div>
      ))}
    </div>
  )
}