import { useDebounce } from '@ritmo/ui'
import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import { useRef } from 'react'
import useSWR from 'swr'
import { offlineTracksDB } from '../../web-adapter/offlineTracksDB'

PouchDB.plugin(PouchDBFind)

const searcher = async (search: string) => {
  if (search.length < 3) return null
  const result = await offlineTracksDB.find({
    selector: {
      $or: [
        { title: { $regex: RegExp(search, 'i') } },
        { artist: { $regex: RegExp(search, 'i') } },
      ]
    },
    fields: ['_id'],
  })
  return result.docs.map(doc => doc._id)
}

export const useQueueSearch = (search: string): string[] | null => {
  const debouncedSearch = useDebounce(search, 100)
  const fallbackData = useRef<string[]>([])
  const { data } = useSWR<string[] | null>(
    debouncedSearch || '-',
    searcher,
    { revalidateOnFocus: false }
  )
  if (data) fallbackData.current = data
  if (data === undefined) {
    return fallbackData.current
  }
  return data
}
