const outsideUrls = [
  'auto-login',
  'privacy-policy',
  'already-active-session'
]

export const isOutsideMainFlow = () => {
  const url = window.location.href
  return outsideUrls.some((outsideUrl) => url.includes(outsideUrl))
}
