import { Button, Field } from '@ritmo/ui'
import { ChangeEvent, FormEvent, useState } from 'react'
import { LoginData } from '@ritmo/player-core'
import { login } from '../../lib/user/login'
import useRecaptchaToken from '../../lib/useRecaptchaToken'
import { isElectron } from '../../lib/isElectron'

export default function LoginScreen() {
  const [loading, setLoading] = useState(false)
  const [loginData, setLoginData] = useState<LoginData>({
    credential: '',
    password: '',
    recaptchaToken: ''
  })
  const { generateRecaptchaToken } = useRecaptchaToken()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const recaptchaToken = await generateRecaptchaToken() || ''
    await login({ ...loginData, recaptchaToken })
    setLoading(false)
  }

  const register = (name: keyof LoginData) => ({
    required: true,
    value: loginData[name],
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setLoginData(curr => ({
        ...curr,
        [name]: e.target.value
      }))
    }
  })

  const handleAutoLogin = () => {
    window.require('electron').shell.openExternal('https://app.ritmostudio.com/auto-login')
  }

  return (
    <div className="mx-auto w-10/12 md:w-80 flex items-center h-full">
      <div className="w-full">
        <h3 className="text-center mb-10 hidden">
          Iniciar sesión
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="space-y-5">
            <Field label="Usuario">
              <input
                className="input w-full"
                autoComplete="username"
                {...register('credential')}
                readOnly={loading}
                autoCapitalize="none"
              />
            </Field>
            <Field label="Contraseña">
              <input
                className="input w-full"
                autoComplete="current-password"
                {...register('password')}
                readOnly={loading}
                autoCapitalize="none"
                pattern="[0-9]*"
                inputMode="numeric"
                type="password"
              />
            </Field>
          </div>
          <Button
            className="w-full mt-8"
            disabled={loading}
          >
            Iniciar sesión
          </Button>
        </form>
        {isElectron && (
          <>
            <hr className='my-8' />
            <span className='text-sm text-gray-500'>Ya tenés RITMO iniciado en el navegador?</span>
            <Button
              onClick={handleAutoLogin}
              className="w-full mt-2"
              variant="secondary"
            >
              Inicio automático
            </Button>
          </>
        )}
      </div>
      <div className="text-xs absolute left-0 px-3 md:px-10 bottom-10 opacity-70 max-w-sm">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" className="text-blue-800">Privacy Policy</a> and&nbsp;
        <a href="https://policies.google.com/terms" className="text-blue-800">Terms of Service</a> apply.
      </div>
    </div>
  )
}