import { Button } from '@ritmo/ui'

export default function InteractionNeededScreen() {
  return (
    <div
      className="bg-white fixed h-screen w-screen flex items-center justify-center z-50 top-0 left-0"
    >
      <Button
        onClick={() => location.reload()}
        variant="secondary"
      >
        Iniciar
      </Button>
    </div>
  )
}
