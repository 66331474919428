import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ConfigProps, hasLocalDB, ritmoInitWithAuth } from '@ritmo/player-core'
import LoginApp from './LoginApp'
import SplashScreen from './screens/splash/SplashScreen'
import ConnectionNeededScreen from './screens/connection-needed'
import checkInteractionNeeded from './lib/checkInteraction'
import InteractionNeededScreen from './screens/interaction-needed/InteractionNeeded'
import ErrorPage from './ErrorPage'
import checkBrowser from './lib/checkBrowser'
import UnsupportedBrowser from './UnsupportedBrowser'
import ConnectionProvider from './contexts/connection'
import './web-adapter'
import { isOutsideMainFlow } from './lib/outsideMainFlow'
import OutsideMainFlowApp from './OutsideMainFlowApp'
import './lib/tokenFromElectron'
import './lib/updateToast'

const APP_ENV = process.env.REACT_APP_ENV
if (APP_ENV) {
  Sentry.init({
    dsn: 'https://7341ad9ff1de4b22a911ba636c1bbf01@o1302526.ingest.sentry.io/6540081',
    integrations: [new BrowserTracing()],
    tracesSampleRate: APP_ENV === 'production' ? 0.2 : 1.0,
    environment: APP_ENV,
    release: process.env.REACT_APP_VERSION,
    beforeSend(event) {
      if (event.message?.includes('InvalidStateError')) {
        return null
      }
      return event
    },
  })
}

const ritmoConfig: ConfigProps = {
  apiUrl: process.env.REACT_APP_API_URL as string,
  appVersion: process.env.REACT_APP_VERSION as string,
}

type InitializeStatus = Awaited<ReturnType<typeof ritmoInitWithAuth>>
type BrowserStatus = 'interaction_needed' | 'unsupported_browser'

const mapAppsToRender: Record<InitializeStatus | BrowserStatus, () => JSX.Element> = {
  started: App,
  no_valid_token: LoginApp,
  connection_needed: ConnectionNeededScreen,
  interaction_needed: InteractionNeededScreen,
  unsupported_browser: UnsupportedBrowser,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <SplashScreen />
  </React.StrictMode>
)

async function checksAndInit() {
  try {
    await checkBrowser()
  } catch {
    return 'unsupported_browser'
  }
  try {
    await checkInteractionNeeded()
  } catch (err) {
    return 'interaction_needed'
  }
  return ritmoInitWithAuth(ritmoConfig)
}

async function startMainFlow() {
  try {
    const result = await checksAndInit()
    const AppToRender = mapAppsToRender[result]
    root.render(
      <React.StrictMode>
        <ConnectionProvider>
          <AppToRender />
        </ConnectionProvider>
      </React.StrictMode>
    )

    setInterval(async () => {
      if (!(await hasLocalDB())) {
        location.reload()
      }
    }, 10 * 1000)
  } catch (err) {
    console.error(err)
    const error = err as Error
    root.render(
      <React.StrictMode>
        <ErrorPage error={error} />
      </React.StrictMode>
    )
  }
}

if (!isOutsideMainFlow()) {
  startMainFlow()
} else {
  root.render(
    <React.StrictMode>
      <ConnectionProvider>
        <OutsideMainFlowApp />
      </ConnectionProvider>
    </React.StrictMode>
  )
}
serviceWorkerRegistration.register()
