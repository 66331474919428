
import { ArrowLeft } from 'phosphor-react'
import { Link } from 'react-router-dom'
import AdsInfo from './AdsInfo'
import DownloadInfo from './DownloadInfo'
import ScheduleInfo from './ScheduleInfo'

export default function InfoScreen() {

  return (
    <div className="p-5">
      <h3 className="flex items-center mb-10 sticky top-0 bg-gray-100 z-10 py-4">
        <Link to="/" className="mr-4">
          <ArrowLeft />
        </Link>
        Información
      </h3>
      <h4 className="mb-4">Programación para hoy</h4>
      <ScheduleInfo />
      <div className="h-20" />
      <h4 className="mb-4">Música offline</h4>
      <DownloadInfo />
      <div className="h-20" />
      <h4 className="mb-4">Anuncios</h4>
      <AdsInfo />
      <div className="h-20" />
      <h4 className="mb-4">Versión de Ritmo</h4>
      {process.env.REACT_APP_VERSION}
      <div className="h-20" />
    </div>
  )
}