import { Button } from '@ritmo/ui'
import toast from 'react-hot-toast'
import { hardReload } from './hardReload'

const updateToast = (
  <div className="flex items-center space-x-6 rounded-lg bg-accent shadow-xl py-5 px-7 text-white">
    <span>Nueva version disponible!</span>
    <Button
      variant="custom"
      className="border border-white"
      onClick={async (e) => {
        e.currentTarget.disabled = true
        hardReload()
      }}
    >
      Actualizar
    </Button>
  </div>
)

document.addEventListener('swUpdated', () => {
  toast.custom(updateToast, { duration: Infinity })
})
