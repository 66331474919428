import { offline_events_db } from '@ritmo/player-core'
import { offlineEventsDB } from './offlineEventsDB'

offline_events_db.allDocs = async <M>() => {
  const { rows } = await offlineEventsDB.allDocs<M>({
    include_docs: true,
  })
  return rows
    .filter(r => r.doc)
    .map(row => row.doc as M)
}

offline_events_db.put = async <M extends { _id: string }>(doc: M) => {
  await offlineEventsDB.put(doc)
}

offline_events_db.removeAllDocs = async <M>() => {
  const offlineEvents = await offlineEventsDB.allDocs<M>()
  await Promise.all(offlineEvents.rows.map(
    async event => offlineEventsDB.remove(event.id, event.value.rev))
  )
}

offline_events_db.exists = async () => {
  try {
    await offlineEventsDB.info()
    return true
  } catch (err) {
    return false
  }
}
