import { Megaphone } from 'phosphor-react'
import { useMemo } from 'react'
import useSWR from 'swr'
import DownloadDetail from '../../components/DownloadDetail/DownloadDetail'
import {
  getAdsDownloadDetails,
  getAllDocsByKey,
  isAdsPackAvailable,
} from '@ritmo/player-core'
import { AdsPack, Partitions, Shift } from '@ritmo/types'
import { getOrderedShifts } from '@ritmo/player-core'
import { DaysIndicator } from '@ritmo/ui'

const emptyToast = (
  <div className="flex items-center py-5 bg-slate-200/50 rounded-md px-5 max-w-md space-x-5">
    <Megaphone size={40} opacity={0.8} className="text-accent" />
    <div className="text-gray-800">
      <h4>No hay anuncios asignados para hoy</h4>
    </div>
  </div>
)

interface ShiftsIndicatorProps {
  packShifts: string[]
  clientShifts: Shift[]
}

const ShiftsIndicator = ({ packShifts, clientShifts }: ShiftsIndicatorProps) => {

  const shifts = useMemo(() => {
    return getOrderedShifts(clientShifts, packShifts)
  }, [clientShifts, packShifts])

  return (
    <div className="text-sm opacity-70 flex flex-wrap gap-2 mt-2">
      {shifts.map(shift => (
        <div key={shift._id} className="rounded border border-slate-300 px-1">
          {shift.title}
        </div>
      ))}
    </div>
  )
}

export default function AdsInfo() {

  const { data: clientShifts } = useSWR<Shift[]>(
    Partitions.TURN,
    getAllDocsByKey,
    { refreshInterval: 2000, fallbackData: [] }
  )

  const { data: adsPacks } = useSWR<AdsPack[]>(
    Partitions.PUBL,
    getAllDocsByKey,
    { refreshInterval: 2000, fallbackData: [] },
  )
  const { data: downloadPerAdsPack } = useSWR(
    'ads-download-details',
    getAdsDownloadDetails,
    { refreshInterval: 2000, fallbackData: {} },
  )

  const filteredPacks = useMemo(() => {
    return adsPacks?.filter(isAdsPackAvailable) || []
  }, [adsPacks])

  if (!filteredPacks.length) return emptyToast

  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 md:gap-4">
      {filteredPacks.map(adsPack => (
        <div
          key={adsPack._id}
          className="py-4 border p-4 rounded-md"
        >
          <h4>{adsPack.title}</h4>
          <div className="h-2" />
          <DaysIndicator
            days={adsPack.days}
            readOnly
            small
          />
          <div className="h-2" />
          {adsPack.oneShotTime ? (
            <div className="text-sm opacity-70 mt-2 rounded border border-slate-300 px-1 inline-flex">
              {adsPack.oneShotTime}
            </div>
          ) : (
            <ShiftsIndicator
              packShifts={adsPack.shifts}
              clientShifts={clientShifts || []}
            />
          )}
          <div className="h-4" />
          {downloadPerAdsPack?.[adsPack._id] ? (
            <DownloadDetail
              playlistName=""
              {...downloadPerAdsPack?.[adsPack._id]}
            />
          ) : null}
        </div>
      ))}
    </div>
  )
}