import { main_db } from '@ritmo/player-core'
import { localDB } from './localDB'


main_db.allDocsByKey = async <T>(key: string) => {
  const { rows } = await localDB.allDocs<T>({ startkey: key, endkey: `${key}\ufff0`, include_docs: true })
  return rows.map(row => row.doc as T)
}

main_db.get = <M>(dodId: string) => localDB.get<M>(dodId)

main_db.put = async (doc: { _rev?: string, _id: string }) => {
  try {
    const savedDoc = await localDB.get(doc._id)
    doc._rev = savedDoc._rev
  } catch (err) {
    delete doc._rev
  }
  await localDB.put(doc)
}

main_db.remove = async (docId) => {
  const doc = await localDB.get(docId)
  await localDB.remove(doc._id, doc._rev)
}

main_db.removeAllDocs = async () => {
  const allDocs = await localDB.allDocs()
  await Promise.all(allDocs.rows.map(
    async doc => localDB.remove(doc.id, doc.value.rev))
  )
}

main_db.exists = async () => {
  try {
    await localDB.info()
    return true
  } catch (err) {
    return false
  }
}
