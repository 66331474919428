import { RitmoToken, setLocalToken } from '@ritmo/player-core'
import { isElectron } from './isElectron'

if (isElectron) {
  const { ipcRenderer } = window.require('electron')
  ipcRenderer.on('open-url-event', (e: Event, ritmoToken: RitmoToken) => {
    setLocalToken(ritmoToken).then(() => {
      window.location.reload()
    })
  })
}
