import { Howl } from 'howler'
import sample from '../components/StayAlive/alive-sound.wav'

export default function checkInteractionNeeded() {
  return new Promise<void>((resolve, reject) => {
    const sound = new Howl({
      src: [sample],
      html5: true,
      volume: 0.0001,
      onplayerror: () => reject(),
      onend: () => resolve(),
    })

    sound.play()
  })
}
