import { Outlet } from 'react-router-dom'
import PlayBarDevOptions from '../Dev/PlayBarDevOptions'
import Header from '../Header'
import { useCore } from '../../lib/useCore'
import WaitToSyncModal from '../WaitToSyncModal'
import { PlayerPlayBar } from './PlayBar'
import { useRecoilValue } from 'recoil'
import { firstSyncCompleteState } from '../../atoms/sync'

export default function Layout() {
  const firstSyncComplete = useRecoilValue(firstSyncCompleteState)

  useCore()

  return (
    <>
      <div className="grid grid-rows-layout h-s-screen bg-gray-100">
        <Header />
        <main className="overflow-y-auto">
          <div className="max-w-7xl mx-auto h-full">
            <Outlet />
          </div>
        </main>
        <PlayerPlayBar />
      </div>
      <PlayBarDevOptions />
      {!firstSyncComplete && <WaitToSyncModal />}
    </>
  )
}
